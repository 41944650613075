@import "../../../bootstrap/functions";
@import "../../variables";
@import "../../../bootstrap/mixins";

// Font
@import "../../font/font-face-en";

/*
Tracking pixel in combination with cookie banner create gap in layout (pixel created in the scope of this ticket WAN-2266 by @Najbrt).
This pixel is dynamically injected to all en-us pages
*/
img[width="1"][height="1"].optanon-category-C0004 {
	position: absolute;
	left: -100vw;
}
